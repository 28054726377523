import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const PureVideo = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 800, quality: 85) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
        const image = data.images.edges.find(n => {
            return n.node.relativePath.includes(props.poster);
        });

        if (!image) {
            return null;
        }

        return (
            <video
                src={props.src}
                poster={image.node.childImageSharp.fluid.src}
                controls
                autoPlay
                loop
            />
        )
    }}
  />
);

export default PureVideo;