import React from 'react'
import { Link } from 'gatsby'

const TextArea = ( props ) => (

	<div className={ props.cssClass }>
	    <div className="container">
	        <div className="text-wrapper">
	            <div className="text">
	                <h3>{ props.heading }</h3>
	                <div className="text__inner">
	                    { props.children }
	                </div>
	            </div>
	        </div>
			{ props.btnLabel ? <Link to="{ props.btnAction }" className="btn btn--primary btn--red">{ props.btnLabel }</Link> : null }
	    </div>
	</div>

)

export default TextArea